.mailListContainer {
  display: flex;
  flex-direction: column;
  gap: 0.625rem;
  overflow-y: auto;
}

.mailItem {
  background-color: #f7f9fc;
  border-radius: 0.625rem;
  padding: 1rem;
  display: flex;
  cursor: pointer;
  transition: background-color 0.3s ease;
  color: #000;

  &:hover {
    background-color: #e6edf7;
  }
}

.selected {
  background-color: #10396b;
  color: white;

  &:hover {
    color: #000;
    background-color: #e6edf7;
  }
}

.emailContent {
  display: flex;
  align-items: center;
  width: 100%;
}

.avatar {
  width: clamp(2.5rem, 5vw, 3rem);
  height: clamp(2.5rem, 5vw, 3rem);
}

.emailDetails {
  margin-left: 1rem;
  flex: 1;
  overflow: hidden;
}

.emailHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;
}

.emailSender {
  font-weight: bold;
  color: inherit;
  font-size: clamp(0.575rem, 1vw, 0.8rem);
}

.emailTime {
  font-weight: bold;
  font-size: clamp(0.45rem, 0.675rem, 0.875rem);
  color: inherit;
}

.emailSnippet {
  color: inherit;
  opacity: 0.8;
  margin-top: 0.3125rem;
  font-size: clamp(0.75rem, 0.875rem, 0.875rem);
  line-height: 1.4;
  height: 2rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
