@import 'assets/styles/variables';

.composeMailContainer {
  width: clamp(300px, 50vw, 700px);
  background-color: #ffffff;
  border-radius: 1rem;
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: $primary-color !important;
  padding: clamp(0.5rem, 1vw, 1rem);
  border-radius: 8px 8px 0 0;
}

.title {
  font-size: clamp(1rem, 2vw, 1.5rem);
  color: #ffffff;
}

.closeButton {
  color: #ffffff !important;
}

.body {
  padding: clamp(1rem, 2vw, 2rem) clamp(0.5rem, 1vw, 1rem);
  display: flex;
  flex-direction: column;
  gap: clamp(0.5rem, 1vw, 1rem);
}

.inputField {
  margin-bottom: clamp(0.5rem, 1vw, 1rem);
}

.textArea {
  resize: none;
  margin-top: clamp(0.5rem, 1vw, 1rem);
}

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: clamp(0.5rem, 2vw, 1rem);
  border-top: 1px solid #e0e0e0;
}

.footer2 {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: clamp(0.5rem, 2vw, 1rem);
  border-top: 1px solid #e0e0e0;
}

.iconsContainer {
  display: flex;
  overflow-x: auto;
}

.submitButton {
  background-color: $primary-color !important;
  color: #ffffff;
  &:hover {
    background-color: darken($primary-color, 10%) !important;
  }
}
