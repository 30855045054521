@import 'assets/styles/variables';

.settingsContainer {
  .pageTitle {
    font-weight: bold;
    color: #003466;
    margin-bottom: 1rem;
  }

  .formContainer {
    border-radius: 8px;
  }

  .profileHeader {
    text-align: center;
    margin-bottom: 2rem;
    border-radius: 1rem;
    background: #f4f7fcbf;
    padding: 1rem;

    .avatarContainer {
      position: relative;
      width: 150px;
      height: 150px;
      display: inline-block;

      .avatar {
        width: 100%;
        height: 100%;
        border-radius: 50%;
      }

      .imageLoader {
        position: absolute;
        top: 37%;
        left: 37%;
        transform: translate(-50%, -50%);
        z-index: 2;
      }

      .cameraIcon {
        position: absolute;
        bottom: 0px;
        right: -10px;
        background-color: white;
        border-radius: 50%;
        z-index: 3;
      }
    }

    .userName {
      font-weight: bold;
      color: #003466;
    }
  }

  .formFields {
    margin-bottom: 2rem;

    .MuiTextField-root {
      .MuiOutlinedInput-root {
        border-radius: 8px;
        background-color: #f4f7fc;
      }

      .MuiInputLabel-outlined {
        color: #666666;
      }
    }
  }

  .actionButtons {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;

    .cancelButton {
      border-radius: 8px;
      color: #003466;
      border-color: #003466;
      text-transform: none;

      &:hover {
        background-color: #f4f7fc;
        border-color: #003466;
      }
    }

    .saveButton {
      border-radius: 8px;
      background-color: #003466;
      text-transform: none;

      &:hover {
        background-color: #002244;
      }
    }
  }
}
