.box {
  border-radius: 0.5rem;
  padding: 1rem;
  border: 1px solid #e0e0e0;
}

.header {
  display: flex;
  // justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  gap: 1rem;
  flex-wrap: wrap;
}

.heading {
  font-size: 1.5rem;
  font-weight: bold;
  color: #333;
}

.dropdown {
  select {
    padding: 0.25rem 1rem;
    font-size: 0.9rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    background-color: #f9f9f9;
    color: #333;

    &:hover {
      background-color: #f1f1f1;
    }
  }
}
@media (max-width: 768px) {
  .header {
    flex-direction: column; /* Stack elements vertically on small screens */
    align-items: flex-start; /* Align dropdowns to the start */
  }

  .dropdown {
    width: 100%; /* Full width dropdown on small screens */
  }
}

@media (min-width: 769px) and (max-width: 1024px) {
  .header {
    flex-direction: row; /* Keep elements in row for medium screens */
    justify-content: space-between;
  }
}
