// Color Variables
$primary-color: #003466;
$secondary-color: #fece00;
$background-color: #fafafa;
$text-color: #333;
$dark-text-color: #002b54;
$icon-color: #678182;
$stats-text-color: #a3aed0;
$stats-count-color: #2b3674;
$chart-color: #718ebf;
$border-color: #e0e0e0;
$sub-menues-color: #ffec9d;
