@import 'assets/styles/variables';

.iconContainer {
  display: flex;
  align-items: center;
}

.iconButton {
  color: $icon-color;
  margin-right: clamp(0.5rem, 1vw, 1rem);
}
