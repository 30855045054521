.dashboardContainer {
  display: flex;
  padding: 1rem;
  border-radius: 0.6rem;
  background: #edf2fc;
  margin: 1rem;
  height: 100%;
}

.noMailSelected {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 1.5rem;
  color: #666;
}
