.uploadContainer {
  padding: 2rem;
  border-radius: 8px;
  max-width: 600px;
  margin: auto;
  box-shadow: 0px 1px 5px 0px #00285c26;
  background-color: #ffffff;

  .closeButton {
    position: absolute;
    top: 10px;
    right: 10px;
    z-index: 10;
  }
}

.title {
  color: #003466;
  font-weight: bold;
  margin-bottom: 1rem;
}

.subtitle {
  color: #707070 !important;
  margin-bottom: 2rem !important;
}

.fileUploadBox {
  border: 3px dashed #d1d5db;
  border-radius: 8px;
  padding: 2rem;
  text-align: center;
  margin-bottom: 2rem;
  background-color: #fff;
}

.fileDetailsBox {
  display: flex;
  align-items: center;
  background-color: #f4f7fc;
  padding: 1rem;
  border-radius: 8px;
  margin-bottom: 2rem;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
}

.fileIcon {
  display: flex;
  align-items: center;
  margin-right: 10px;

  img {
    width: 36px;
    height: 36px;
  }
}

.fileName {
  font-size: 1rem;
  font-weight: 600;
  color: #000000;
}

.fileSize {
  font-size: 0.875rem;
  color: #6b7280 !important;
}

.cancelButton {
  margin-left: auto;
}

.progressBar {
  margin-top: 0.5rem;
  height: 6px !important;
  border-radius: 4px;
  background-color: #e5e7eb;

  .MuiLinearProgress-bar {
    background-color: #1b5c9a !important;
  }
}

.uploadButton {
  text-transform: none;
  border-radius: 8px;
  background-color: #003366;
  &:hover {
    background-color: #002244;
  }
}
